import type { JSX } from 'react';

import { Helmet } from 'react-helmet-async';

import { stripHtmlTags } from '@change/core/html';
import { useI18n } from '@change/core/react/i18n';

import { usePetitionDetails } from '../../hooks/petitionDetails';
import { useShareHeadline } from '../../hooks/shareHeadline';

export function MetaDescriptionContainer(): JSX.Element | null {
	const { status, signatureState, ask, description } = usePetitionDetails();
	const { signatureCount } = signatureState;
	const { translate, translatePlural } = useI18n();
	const shareHeadline = useShareHeadline();

	const ogTitleContent =
		status === 'VICTORY'
			? translatePlural('fe.facebook.share.victory', signatureCount.displayed, {
					signature_count: signatureCount.displayedLocalized,
				})
			: shareHeadline?.title || translate('fe.facebook.share.sign_petition');

	const ogDescriptionContent = status === 'VICTORY' ? ask : shareHeadline?.description || ask;

	return (
		<Helmet>
			<meta property="og:title" content={ogTitleContent} />
			<meta property="og:description" content={ogDescriptionContent} />
			{description && <meta name="description" content={stripHtmlTags(description).substr(0, 400)} />}
			<meta property="twitter:image:alt" content={ogTitleContent} />
		</Helmet>
	);
}

import { Box } from '@change/design-system/layout';
import { Heading, Text } from '@change/design-system/typography';

import { useSignaturesDistributionContext } from '../../shared/provider';
import { Loaders } from '../Loaders';
import { SignatureMapConfigurator } from '../SignatureMapConfigurator';
import { useSignaturesDistributionHidden } from '../SignaturesDistributionHidden';
import { SignaturesDistributionInfo } from '../SignaturesDistributionInfo';

import { useClusterPercentage } from './hooks/useClusterPercentage';
import { useFetchSignaturesDistribution } from './hooks/useFetchSignaturesDistribution';
import { useSignaturesDistribution } from './hooks/useSignaturesDistribution';
import { useZoomLevel } from './hooks/useZoomLevel';

type Props = {
	isStaff: boolean;
};

export function SignaturesDistribution({ isStaff }: Props): React.JSX.Element | null {
	const {
		data: { hasNoCluster, isNotInSignatureCountRange, noData },
	} = useSignaturesDistributionContext();

	useFetchSignaturesDistribution();
	useSignaturesDistribution(isStaff);
	useZoomLevel();
	useClusterPercentage();
	useSignaturesDistributionHidden({
		noClusterFound: hasNoCluster,
		countNotInRange: isNotInSignatureCountRange,
	});

	if (hasNoCluster || isNotInSignatureCountRange) {
		return null;
	}

	return (
		<Box my={16} px={16} data-qa="supporter-map-container">
			<Heading as="h2" size={['h3']} data-qa="supporter-map-heading">
				Supporter Map
			</Heading>

			<Loaders />
			{noData && <Text>No data available</Text>}
			<SignaturesDistributionInfo isStaff={isStaff} />
			{isStaff && <SignatureMapConfigurator />}
		</Box>
	);
}

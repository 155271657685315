import type { JSX } from 'react';

import { Helmet } from 'react-helmet-async';

import { useUtilityContext } from '@change/core/react/utilityContext';

import { useMitaPhoto } from '../../hooks/mita';
import { usePetitionDetails } from '../../hooks/petitionDetails';

export function MetaPhotoContainer(): JSX.Element | null {
	const petition = usePetitionDetails();
	const { resolveUrl } = useUtilityContext();
	const mitaPhoto = useMitaPhoto();

	const photo = mitaPhoto || petition.photo?.petitionLarge;

	return (
		<>
			<Helmet>
				<meta name="twitter:card" content="summary_large_image" />
			</Helmet>

			{/* tags must be direct children of Helmet */}
			{photo ? (
				<Helmet>
					<meta property="og:image" content={resolveUrl(photo.url)} />
					<meta property="og:image:secure_url" content={resolveUrl(photo.url)} />
					{/* https://github.com/jsx-eslint/eslint-plugin-react/issues/3607 */}
					{/* eslint-disable-next-line react/jsx-curly-brace-presence */}
					<meta property="og:image:width" content={`${photo.width}`} />
					{/* https://github.com/jsx-eslint/eslint-plugin-react/issues/3607 */}
					{/* eslint-disable-next-line react/jsx-curly-brace-presence */}
					<meta property="og:image:height" content={`${photo.height}`} />
					<meta property="twitter:image" content={resolveUrl(photo.url)} />
				</Helmet>
			) : (
				<Helmet>
					<meta property="og:image" content="https://static.change.org/images/default-petition.gif" />
					<meta property="og:image:secure_url" content="https://static.change.org/images/default-petition.gif" />
					<meta property="og:image:width" content="1120" />
					<meta property="og:image:height" content="630" />
					<meta property="twitter:image" content="https://static.change.org/images/default-petition.gif" />
				</Helmet>
			)}
		</>
	);
}

import type { QueryHookResult } from '@change/core/react/async';
import { useQuery } from '@change/core/react/async';
import { useUtilityContext } from '@change/core/react/utilityContext';

import type { PetitionContentUpdate, PetitionUpdates } from 'src/app/pages/petitionGamma/details/shared/api';

import { getDecisionMakerResponses } from './api/decisionMakerResponses';

export type DecisionMakerResponsesState = QueryHookResult<
	Readonly<{ updates: PetitionUpdates<PetitionContentUpdate> | undefined }>
>;

export function useApiDecisionMakerResponses(petitionId: string, count: number): DecisionMakerResponsesState {
	const utilityContext = useUtilityContext();

	return useQuery(
		async () => ({
			updates: await getDecisionMakerResponses(petitionId, count, utilityContext),
		}),
		[petitionId, count, utilityContext],
	);
}

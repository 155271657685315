import type { JSX } from 'react';

import { Translate } from '@change/core/react/i18n';
import { Box, Flex } from '@change/design-system/layout';
import { Text } from '@change/design-system/typography';

import { ResponsiveBox } from 'src/app/pages/petitionGamma/details/shared/components';
import { AfterMobileActionButtonWaypoint } from 'src/app/pages/petitionGamma/details/shared/waypoints';

import { usePetitionDetails } from '../../hooks/petitionDetails';
import { MobileActionButtonContainer } from '../mobileActionButton';
import { SignatureStatsMobileContainer } from '../stats/signatureStats';

import { DecisionMakerContent } from './components/DecisionMakerContent';
import { RecentSigners } from './components/RecentSigners';

export function MetaAttributes(): JSX.Element {
	const { createdAtLocalized, primaryDecisionMakerConnection } = usePetitionDetails();

	const { totalCount: decisionMakerCount } = primaryDecisionMakerConnection;
	const hasDecisionMakers = decisionMakerCount > 0;

	return (
		<>
			<ResponsiveBox viewportSize="normal">
				<Text color="typography-secondary" size="small">
					<Flex>
						<Box sx={{ flex: ['2', '1'] }}>
							<Translate value="corgi.petition.details.started_at" />
						</Box>
						<Box sx={{ flex: '4' }}>{createdAtLocalized}</Box>
					</Flex>
					{hasDecisionMakers && (
						<Flex>
							<Box sx={{ flex: ['2', '1'] }}>
								<Translate value="corgi.petition.details.petition_to" />
							</Box>
							<Box sx={{ flex: '4' }}>
								<DecisionMakerContent decisionMakers={primaryDecisionMakerConnection} />
							</Box>
						</Flex>
					)}
				</Text>
			</ResponsiveBox>

			<ResponsiveBox viewportSize="small">
				<MobileActionButtonContainer sticky={false} />
				<AfterMobileActionButtonWaypoint />
				<SignatureStatsMobileContainer />
				<RecentSigners />
			</ResponsiveBox>
		</>
	);
}

import type { JSX } from 'react';

import { PerimeterXSensorContainer } from 'src/app/shared/components/head';

import { usePetitionDetails } from '../../hooks/petitionDetails';

import { CanonicalUrlContainer } from './CanonicalUrl';
import { MetaContainer } from './Meta';
import { TitleContainer } from './Title';

export function HeadContainer(): JSX.Element {
	const petition = usePetitionDetails();
	return (
		<>
			<TitleContainer />
			<CanonicalUrlContainer />
			<MetaContainer />
			<PerimeterXSensorContainer petitionId={petition.id} />
		</>
	);
}

import { useCallback, useEffect, useMemo, useState } from 'react';

import { useUtilityContext } from '@change/core/react/utilityContext';
import { Button } from '@change/design-system/components/actions';
import { InlineMessage } from '@change/design-system/components/alerts';
import { Loader } from '@change/design-system/components/progressiveDisclosure';
import { Box, Flex } from '@change/design-system/layout';
import { createModalHook } from '@change/design-system/modals';
import { Text } from '@change/design-system/typography';

import { useCampaignTracking } from 'src/app/shared/hooks/campaignTracking';

import { deleteMediaHit } from '../../../api/deleteMediaHit';
import type { MediaHitEmbed } from '../../../shared/types';

export const useDeleteMediaModal = createModalHook<
	{
		petitionId: string;
		media: MediaHitEmbed;
	},
	{ value: 'success' | 'error' | undefined }
>({
	name: 'Delete Media Hit',
	title: 'Delete Media Hit',
	body: function DeleteModalBody({ options: { media, petitionId }, closeModal }) {
		const [isDeleteLoading, setIsDeleteLoading] = useState(false);
		const [deleteStatus, setDeleteStatus] = useState<'success' | 'error' | undefined>(undefined);

		const utilityContext = useUtilityContext();

		const { track, trackError } = useCampaignTracking();

		// todo: remove this, potential cause of accessibility issues
		useEffect(() => {
			if (deleteStatus === 'success') {
				setTimeout(() => {
					closeModal({ value: deleteStatus });
				}, 1000);
			}
		}, [deleteStatus, closeModal]);

		const trackingData = useMemo(() => {
			return {
				petition_id: petitionId,
				media_id: media.id,
				media_url: media.url,
			};
		}, [petitionId, media]);

		const handleCancel = useCallback(() => {
			void track('campaign_media_hit_delete_modal_cancel_button_click', {
				...trackingData,
			});
			closeModal({ value: deleteStatus });
		}, [closeModal, deleteStatus, track, trackingData]);

		const handleDelete = useCallback(async () => {
			void track('campaign_media_hit_delete_modal_delete_button_click', {
				...trackingData,
			});
			setIsDeleteLoading(true);
			try {
				const deleteResult = await deleteMediaHit(media.id, utilityContext);
				if (deleteResult?.deleteComment?.success) {
					setDeleteStatus('success');
					void track('campaign_media_hit_delete_success', {
						...trackingData,
					});
				} else {
					setDeleteStatus('error');
					void track('campaign_media_hit_delete_failure', {
						...trackingData,
						...(deleteResult?.deleteComment?.reason ? { errors: deleteResult.deleteComment.reason } : {}),
					});
				}
			} catch (err) {
				setDeleteStatus('error');
				void trackError('campaign_media_hit_delete_error', err, {
					context: { ...trackingData },
				});
			} finally {
				setIsDeleteLoading(false);
			}
		}, [media, track, trackError, trackingData, utilityContext]);

		return (
			<Box data-testid="delete-confirmation" sx={{ flexDirection: 'column' }}>
				<Text as="p" mb={32}>
					Are you sure you want to permanently media hit from {media.url}?
				</Text>
				<Flex sx={{ justifyContent: 'center' }}>
					{isDeleteLoading && <Loader mb={16} data-testid="delete-loader" />}
				</Flex>
				{deleteStatus === 'success' && (
					<InlineMessage variant="success" title="Success" mb={8}>
						Media hit successfully removed.
					</InlineMessage>
				)}
				{deleteStatus === 'error' && (
					<InlineMessage variant="warning" title="Delete failed" mb={8}>
						Something went wrong while deleting.
					</InlineMessage>
				)}
				{deleteStatus !== 'success' && (
					<Flex sx={{ width: '100%', flexDirection: ['column', 'row'], gap: 16, justifyContent: 'flex-end' }}>
						<Button
							variant="secondaryEmphasis"
							data-testid="confirm-cancel-btn"
							onClick={handleCancel}
							disabled={isDeleteLoading}
						>
							Cancel
						</Button>
						<Button
							variant="primary"
							data-testid="confirm-delete-btn"
							onClick={handleDelete}
							disabled={isDeleteLoading}
							sx={{ textTransform: 'uppercase' }}
						>
							Permanently Remove
						</Button>
					</Flex>
				)}
			</Box>
		);
	},

	closeButton: false,
	closeOnClickOutside: false,
});

import type { JSX } from 'react';

import { Translate } from '@change/core/react/i18n';

import { ActionButtonWrapper } from './ActionButtonWrapper';
import { CustomButton } from './CustomButton';
import { useMobileShareModal, usePreloadMobileShareModal } from './MobileShareModal';

type Props = {
	sticky: boolean;
	visible: boolean;
	hideSocialProof?: boolean;
};

export function MobileShareButtonContainer({ sticky, visible, hideSocialProof = false }: Props): JSX.Element {
	const openModal = useMobileShareModal();

	usePreloadMobileShareModal();

	return (
		<ActionButtonWrapper visible={visible} sticky={sticky} hideSocialProof={hideSocialProof}>
			<CustomButton inverted onClick={openModal} data-qa="mobile-share-button">
				<Translate value="fe.components.petition_share.action" />
			</CustomButton>
		</ActionButtonWrapper>
	);
}

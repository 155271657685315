import type { JSX } from 'react';

import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router';

import { useUtilityContext } from '@change/core/react/utilityContext';

import { useIsIndexed } from './hooks/useIsIndexed';

export function CanonicalUrlContainer(): JSX.Element {
	const utilityContext = useUtilityContext();
	const { pathname } = useLocation();
	const canonicalUrl = utilityContext.resolveUrl(pathname);
	const indexed = useIsIndexed();

	return (
		<Helmet>
			<meta property="og:url" content={canonicalUrl} />
			{/* only adding the canonical url if noindex is absent, as they are conflicting tags for SEO indexers */}
			{/* FIXME? should we use share urls here or should we use the simple /p/slug url? */}
			{indexed && <link rel="canonical" href={canonicalUrl} />}
		</Helmet>
	);
}

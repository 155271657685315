import type { QueryHookResult } from '@change/core/react/async';
import { useQuery } from '@change/core/react/async';
import { useUtilityContext } from '@change/core/react/utilityContext';

import type { DecisionMakers } from './api/decisionMakers';
import { getDecisionMakers } from './api/decisionMakers';

export type DecisionMakersState = QueryHookResult<Readonly<{ decisionMakers: DecisionMakers }>>;

export type { DecisionMaker, DecisionMakers } from './api/decisionMakers';

export function useApiDecisionMakers(slug: string, numDecisionMakers: number): DecisionMakersState {
	const utilityContext = useUtilityContext();

	return useQuery(
		async () => ({
			decisionMakers: await getDecisionMakers(slug, numDecisionMakers, utilityContext),
		}),
		[slug, numDecisionMakers, utilityContext],
	);
}

import type { JSX } from 'react';

import { withPrefetchedData } from '@change/core/react/prefetch';
import { useKnownAvailableParams } from '@change/core/react/router';
import { Loader } from '@change/design-system/components/progressiveDisclosure';

import type { PetitionDetailsPagePrefetchedDataProps } from 'src/app/pages/petitionGamma/details/pageContext';
import {
	PetitionDetailsPageContextProvider,
	prefetchPageContext,
	prefetchPageUserContext,
} from 'src/app/pages/petitionGamma/details/pageContext';

import type { PetitionGammaCommonProps } from '../../shared/types';

import { DetailsWithContext } from './DetailsWithContext';
import { PetitionDetailsErrorHandler } from './ErrorHandler';

type Props = PetitionGammaCommonProps &
	Readonly<{
		slug: string;
	}>;

export const CommonPetitionDetails = withPrefetchedData(
	function CommonPetitionDetails({
		prefetchedData,
		prefetchedUserData,
		userInfoState,
	}: Props & PetitionDetailsPagePrefetchedDataProps): JSX.Element {
		const { slug } = useKnownAvailableParams<{ slug: string }>();

		return (
			<PetitionDetailsPageContextProvider
				slug={slug}
				prefetchedData={prefetchedData}
				prefetchedUserData={prefetchedUserData}
				userInfo={userInfoState}
				errorHandler={PetitionDetailsErrorHandler}
				loadingFallback={<Loader size="m" my={256} mx="auto" />}
			>
				<DetailsWithContext slug={slug} />
			</PetitionDetailsPageContextProvider>
		);
	},
	{
		prefetchName: __MODULE_HASH__,
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		prefetchData: async (context) => prefetchPageContext(context.params.slug!, context),
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		prefetchUserData: async (context) => prefetchPageUserContext(context.params.slug!, context),
	},
);

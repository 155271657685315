import React from 'react';

import { Box, Flex } from '@change/design-system/layout';
import { Heading } from '@change/design-system/typography';

import { usePetitionDetails } from '../../../../hooks/petitionDetails';
import { useSignaturesDistributionContext } from '../../shared/provider';

export function SignatureMapConfigurator(): React.JSX.Element | null {
	const { id } = usePetitionDetails();
	const {
		data: { zoomLevel, clusterSize },
		actions: { setZoomLevel, setClusterSize, setRerunNow },
	} = useSignaturesDistributionContext();

	const onZoomLevelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setZoomLevel(parseFloat(event.target.value));
		setRerunNow(true);
	};

	const onClusterSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setClusterSize(parseFloat(event.target.value));
		setRerunNow(true);
	};

	if (!zoomLevel || !clusterSize) {
		return null;
	}
	return (
		<Box mt={8} sx={{ overflow: 'scroll' }} data-qa="supporter-map-config-container">
			<Heading as="h3" size={['h4']} data-qa="supporter-map-config-heading">
				Map Configuration
			</Heading>
			<Flex sx={{ flexDirection: 'column' }}>
				<label htmlFor="cluster-size-edit">Cluster size ({clusterSize})</label>
				<input
					id="cluster-size-edit"
					data-testid="cluster-size-edit"
					type="range"
					defaultValue={clusterSize}
					onChange={onClusterSizeChange}
					min="0.1"
					max="3"
					step="0.01"
				/>
			</Flex>
			<Flex sx={{ flexDirection: 'column' }}>
				<label htmlFor="zoom-level-edit">Zoom Level ({zoomLevel})</label>
				<input
					id="zoom-level-edit"
					data-testid="zoom-level-edit"
					defaultValue={zoomLevel}
					onChange={onZoomLevelChange}
					type="range"
					name="zoom-level"
					min="1"
					max="15"
					step="0.2"
				/>
			</Flex>

			<pre data-qa="map-settings">{JSON.stringify({ petitionId: id, clusterSize, zoomLevel })}</pre>
		</Box>
	);
}

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';

import { isError, isLoading } from 'src/app/shared/utils/async';

import { useAsyncFcm } from '../../hooks/fcm';
import { usePetitionDetails } from '../../hooks/petitionDetails';

import { useUserState } from './hooks/userUserState';
import { SupporterVoicesComponent } from './Layout';
import { shouldShowSupporterVoices } from './utils/shouldShowSupporterVoices';

// eslint-disable-next-line complexity
export function SupporterVoices(): React.JSX.Element | null {
	const fcm = useAsyncFcm();
	const userState = useUserState();
	const {
		id: petitionId,
		videoSettingsMetadata: { disableDisplay },
	} = usePetitionDetails();

	if (isLoading(fcm) || isLoading(userState)) {
		return null;
	}
	if (isError(fcm) || isError(userState)) {
		return null;
	}

	const showSupporterVoices = shouldShowSupporterVoices(
		disableDisplay,
		fcm.values.supporterVoicesDetailsPageConfig,
		userState.value.session,
		userState.value.currentUser,
		userState.value.userIsStaff,
		userState.value.userIsStarter,
		petitionId,
	);

	return <SupporterVoicesComponent showSupporterVoices={showSupporterVoices} />;
}

import type { JSX } from 'react';

import { Box } from '@change/design-system/layout';

import { useFcm } from '../../hooks/fcm';
import { useSapCtaTracking } from '../../hooks/tracking/sapCta';
import { useUserInfo } from '../../hooks/userInfo';

import { SapCta } from './components/SapCta';

export function SapCtaContainer(): JSX.Element | null {
	const { sapCtaEnabled } = useFcm();
	const { canEdit, signed } = useUserInfo();
	const { trackClick } = useSapCtaTracking();

	if (canEdit || !sapCtaEnabled || !signed) return null;

	return (
		<Box pt={24}>
			<SapCta onClick={trackClick} />
		</Box>
	);
}

import type { JSX } from 'react';

import { Helmet } from 'react-helmet-async';

import { localeMappings } from '@change/config/legacy/locales'; // TODO move locales mapping to @change/config/facebook
import type { Locale } from '@change/config/locales';

import { usePetitionDetails } from '../../hooks/petitionDetails';

export function MetaLocaleContainer(): JSX.Element | null {
	const petition = usePetitionDetails();

	return (
		<Helmet>
			<meta
				property="og:locale"
				content={
					localeMappings[petition.originalLocale.localeCode as Locale]?.facebook || localeMappings['en-US']?.facebook
				}
			/>
		</Helmet>
	);
}

import React from 'react';

import { Box } from '@change/design-system/layout';
import { Text } from '@change/design-system/typography';

import type { SignaturesCluster } from '../../shared/types';

type Props = {
	signaturesClusters: SignaturesCluster[];
	clusterPercentage: number;
};

export function SignatureDistributionSummary({ signaturesClusters, clusterPercentage }: Props): React.JSX.Element {
	const { contributors } = signaturesClusters[0];

	return (
		<Box
			sx={{
				overflowWrap: 'break-word',
				textAlign: 'left',
				backgroundColor: '#F2F2F2',
				borderBottomLeftRadius: '10px',
				borderBottomRightRadius: '10px',
			}}
			p={16}
			mt={0}
		>
			<Text fontWeight="bold" data-testid="cluster-percentage">
				{clusterPercentage.toFixed(0)}%
			</Text>
			<Text>&nbsp;of supporters come from this area.</Text>
			<br />
			<Text data-qa="top-postal-code">
				Top zip code(s) with the most supporters:&nbsp;
				{contributors
					.filter((_, index) => index < 3)
					.map((e) => `${e.postalCode}`)
					.join(', ')}
			</Text>
		</Box>
	);
}

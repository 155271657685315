import { useMemo } from 'react';

import qs from 'qs';

import { useUtilityContext } from '@change/core/react/utilityContext';

import { useQueryParamValue } from 'src/app/shared/hooks/location';

import { useResponseRequestUrl } from './responseRequestUrl';

type FeedUrlData =
	| {
			feedUrl: undefined;
			responseRequestUrl: undefined;
	  }
	| {
			feedUrl: string;
			responseRequestUrl: string | undefined;
	  };

const NO_URL_STATE = {
	feedUrl: undefined,
	responseRequestUrl: undefined,
};

export function useDmFeedUrls(): FeedUrlData {
	// DM Feed URL is provided by a query param so that it's unambiguous
	// which feed the user came from
	const returnUrl = useQueryParamValue('r');
	const responseRequestUrl = useResponseRequestUrl();
	const { resolveUrl } = useUtilityContext();

	return useMemo(() => {
		if (!returnUrl) return NO_URL_STATE;

		try {
			const parsedUrl = new URL(returnUrl);

			const parsedQuery = qs.parse(parsedUrl.search, { ignoreQueryPrefix: true });

			const newSearch = qs.stringify(
				{
					...parsedQuery,
					source_location: 'dm_back_link',
				},
				{ addQueryPrefix: true },
			);

			return {
				feedUrl: resolveUrl(`${parsedUrl.pathname}${newSearch}${parsedUrl.hash}`),
				responseRequestUrl: parsedQuery.facet === 'mentions' ? responseRequestUrl : undefined,
			};
		} catch (e) {
			// someone's probably messing with the query params
			return NO_URL_STATE;
		}
	}, [returnUrl, resolveUrl, responseRequestUrl]);
}

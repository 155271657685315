/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import type { Session, SessionUser } from '@change/core/session';

export function getDigitFromSessionId(uuid: string) {
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	return Math.floor((parseInt(uuid.at(-1)!, 16) * 10) / 16);
}

// eslint-disable-next-line max-params
export function shouldShowSupporterVoices(
	disableDisplay: boolean | null,
	supporterVoicesDetailsPageConfig: {
		enabled: boolean;
		activatedPercentage: number;
		blocklist: Readonly<Array<{ id: string }>>;
	},
	session: Session,
	currentUser: SessionUser | undefined,
	userIsStaff: boolean,
	userIsStarter: boolean,
	petitionId: string,
): boolean {
	if (disableDisplay) return false;
	if (!supporterVoicesDetailsPageConfig) return false;
	const { activatedPercentage, blocklist } = supporterVoicesDetailsPageConfig;

	const digit = !currentUser ? getDigitFromSessionId(session.uuid) : parseInt(currentUser.id, 10) % 10;
	const threshold = activatedPercentage / 10;
	const isValidUser = userIsStaff || userIsStarter || digit < threshold;

	const isInBlocklist = blocklist?.some((item) => item.id === petitionId);

	return isValidUser && !isInBlocklist;
}

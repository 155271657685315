import { useCallback, useRef } from 'react';

import { Waypoint } from 'react-waypoint';

import { Translate } from '@change/core/react/i18n';
import { useTracking } from '@change/core/react/tracking';
import { FakeLink, Link } from '@change/design-system/components/actions';
import { Box, Flex } from '@change/design-system/layout';
import { Text } from '@change/design-system/typography';

import type { TipCardConfig } from '../types';

export function TipCard({ tip }: { tip: TipCardConfig }) {
	const track = useTracking();
	const viewTracked = useRef(false);
	const trackView = useCallback(() => {
		if (viewTracked.current) return;
		void track('petition_strength_card_view', {
			card: tip.type,
		});
		viewTracked.current = true;
	}, [track, tip.type, viewTracked]);

	return (
		<Flex
			p={24}
			sx={{
				background: 'background',
				borderRadius: 'standard',
				flexDirection: 'column',
				gap: 8,
				height: '100%',
				width: 325,
			}}
		>
			<Text as="p" size="small" fontWeight="bold">
				<Translate value={tip.title} />
			</Text>
			<Text as="p" size="small" color="neutral-grey600">
				<Translate value={tip.body} />
			</Text>
			<Text as="p" size="small">
				{tip.url ? (
					<Link
						to={tip.url}
						onClick={tip.onClick}
						forceMode="external" // necessary for hash link to work
						variant="primary"
					>
						<Translate value={tip.primaryActionText} />
					</Link>
				) : (
					<FakeLink onClick={tip.onClick}>
						<Translate value={tip.primaryActionText} />
					</FakeLink>
				)}
			</Text>
			<Flex>
				<Box sx={{ width: '100%' }} />
				<Waypoint horizontal onEnter={trackView} />
			</Flex>
		</Flex>
	);
}

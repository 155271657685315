import { useCallback } from 'react';

import { useNavigate } from '@change/core/react/router';
import { useTracking } from '@change/core/react/tracking';

import { useCurrentUserAsync } from 'src/app/shared/hooks/session';
import { isLoaded } from 'src/app/shared/utils/async';

import { usePetitionDetails } from '../../../../../hooks/petitionDetails';
import { useUserInfo } from '../../../../../hooks/userInfo';

type Result = ModelHookResult<
	{
		username: string;
		userHasSigned: boolean;
	},
	{
		createVideoClickHandler: () => void;
	}
>;

export function useCreateVideo(): Result {
	const navigate = useNavigate();
	const track = useTracking();
	const { id: petitionId } = usePetitionDetails();
	const { signed } = useUserInfo();
	const currentUserBase = useCurrentUserAsync();
	const currentUser = isLoaded(currentUserBase) && currentUserBase.value?.shortDisplayName;

	const createVideoClickHandler = useCallback(() => {
		void track('supporter-voices-create-a-video-button-click', {
			petition_id: petitionId,
		});

		/**
		 * Although slower, this triggers a full page reload on the /p/:slug/actions page
		 * (ie. beyond react router's client side routing) in order to get the utm data reflecting on amplitude
		 */
		navigate(`/p/${petitionId}/actions?utm_campaign=petition_details`, { forceMode: 'external' });
	}, [navigate, petitionId, track]);

	return {
		data: {
			username: currentUser || '',
			userHasSigned: signed,
		},
		actions: {
			createVideoClickHandler,
		},
	};
}

import { useCallback, useEffect } from 'react';

import { useUtilityContext } from '@change/core/react/utilityContext';

import { usePetitionDetails } from '../../../../../hooks/petitionDetails';
import { useSignaturesDistributionContext } from '../../../shared/provider';
import { fetchSignatures } from '../api/fetchSignatures';

export function useFetchSignaturesDistribution() {
	const utilityContext = useUtilityContext();
	const {
		id,
		signatureState: {
			signatureCount: { displayed },
		},
	} = usePetitionDetails();

	const {
		actions: { setIsLoadingData, setRerunNow, setSignaturesDistribution, setSignaturesCount, setNoData },
	} = useSignaturesDistributionContext();

	const fetchSignaturesDistribution = useCallback(async () => {
		setIsLoadingData(true);
		const data = await fetchSignatures({ petitionId: id, utilityContext });
		if (data) {
			const { distribution } = data;
			setSignaturesDistribution(distribution);
			setSignaturesCount(displayed);
		} else {
			setNoData(true);
		}
		setIsLoadingData(false);
		setRerunNow(true);
	}, [
		displayed,
		utilityContext,
		setIsLoadingData,
		setSignaturesDistribution,
		setSignaturesCount,
		setNoData,
		setRerunNow,
		id,
	]);

	useEffect(() => {
		void fetchSignaturesDistribution();
	}, [fetchSignaturesDistribution]);
}

import { Loader } from '@change/design-system/components/progressiveDisclosure';

import { useSignaturesDistributionContext } from '../../shared/provider';

export function Loaders(): React.JSX.Element {
	const {
		data: { isLoadingData, isCrunchingData },
	} = useSignaturesDistributionContext();

	return (
		<>
			{isLoadingData && <Loader data-testid="loading-data" size="m" my={256} mx="auto" />}
			{isCrunchingData && <Loader data-testid="crunching-data" size="m" my={256} mx="auto" />}
		</>
	);
}

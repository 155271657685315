import { useMemo } from 'react';

import { useTracking } from '@change/core/react/tracking';

import { usePetitionDetails } from '../petitionDetails';

type Result = Readonly<{
	trackClickLink: () => void;
}>;

export function useDescriptionTracking(): Result {
	const { id } = usePetitionDetails();
	const track = useTracking();

	return useMemo(
		() => ({
			trackClickLink: () => {
				const numberId = parseInt(id, 10);
				void track<{ petition_id?: number }>('petition_description_link_clicked', {
					petition_id: Number.isNaN(numberId) ? undefined : numberId,
				});
			},
		}),
		[track, id],
	);
}

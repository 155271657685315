import { useMemo } from 'react';
import type { JSX } from 'react';

import { Html } from '@change/design-system/html';
import { Box } from '@change/design-system/layout';

import { useRichMediaTransformers } from 'src/app/shared/hooks/richMedia';

import { usePetitionDetails } from '../hooks/petitionDetails/petitionDetails';
import { useDescriptionTracking } from '../hooks/tracking/description';

export function DescriptionContainerViewportNormal(): JSX.Element {
	const { trackClickLink } = useDescriptionTracking();

	const details = usePetitionDetails();
	const { decodeRichMediaTransformer } = useRichMediaTransformers();

	const htmlTransformers = useMemo(() => [decodeRichMediaTransformer], [decodeRichMediaTransformer]);

	return (
		<Box data-qa="description-content">
			<Html
				size="default"
				breakWord
				html={details.description}
				sx={{ iframe: { width: '100%', height: 'auto', aspectRatio: '16/9' } }}
				transformers={htmlTransformers}
				onClickLink={trackClickLink}
			/>
		</Box>
	);
}

import { useCallback, useEffect } from 'react';
import type { JSX } from 'react';

import { Translate } from '@change/core/react/i18n';
import { ClientRender } from '@change/core/react/ssr/render';
import { Flex } from '@change/design-system/layout';
import { Text } from '@change/design-system/typography';

import { useSignatureTracking } from '../../hooks/tracking/signature';

import { ActionButtonWrapper } from './ActionButtonWrapper';
import { CustomButton } from './CustomButton';
import { useMobileSignModal, usePreloadMobileSignModal } from './MobileSignModal';
import { useMobileModalContext } from './shared/useMobileModalContext';
import { useShowSignForm } from './shared/useShowSignForm';
import { SignCtaIcon } from './SignCtaIcon';

type Props = {
	sticky: boolean;
	visible: boolean;
};

export function MobileSignButtonContainer({ sticky, visible }: Props): JSX.Element | null {
	const { trackMobileSignIntent, trackClosedModal } = useSignatureTracking();

	const context = useMobileModalContext();
	const openModal = useMobileSignModal();

	usePreloadMobileSignModal();

	const handleModalWithoutTracking = useCallback(async () => {
		await openModal(context).waitForClosed();
	}, [context, openModal]);

	const handleModal = useCallback(async () => {
		trackMobileSignIntent();
		await openModal(context).waitForClosed();
		trackClosedModal();
	}, [context, openModal, trackMobileSignIntent, trackClosedModal]);

	return (
		<>
			{/* we're looking at breakpoints to decide whether to open the modal, which cannot be done server-side */}
			<ClientRender>
				<MobileSignButtonAutoOpen onOpen={handleModalWithoutTracking} />
			</ClientRender>
			<ActionButtonWrapper visible={visible} sticky={sticky}>
				<CustomButton
					onClick={handleModal}
					data-qa="mobile-sign-button"
					data-testid="mobile-sign-button"
					data-gtm="ga4-sign-button"
					size="large"
				>
					<Flex
						sx={{
							placeItems: 'center',
							placeContent: 'center',
							gap: '12px',
						}}
					>
						<Text
							size="large"
							sx={{
								fontSize: '18px',
							}}
						>
							<Translate value="corgi.petition_gamma.petition_sign.action" />
						</Text>
						<SignCtaIcon width="24px" height="24px" />
					</Flex>
				</CustomButton>
			</ActionButtonWrapper>
		</>
	);
}

function MobileSignButtonAutoOpen({ onOpen }: { onOpen: () => void }) {
	const showSignForm = useShowSignForm();

	// FIXME this should be moved somewhere else as this means that rendering MobileSignButtonContainer twice
	// will result in trying to open the modal twice
	useEffect(() => {
		if (showSignForm) {
			onOpen();
		}
		// disabling dependency check to avoid attempting to open the modal multiple times
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return null;
}

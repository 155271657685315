import type { JSX } from 'react';

import { Separator } from '@change/design-system/components/content';
import { Box } from '@change/design-system/layout';

import { usePetitionDetailsPageData } from 'src/app/pages/petitionGamma/details/pageContext';
import { ResponsiveBox } from 'src/app/pages/petitionGamma/details/shared/components';
import { AfterDescriptionWaypoint } from 'src/app/pages/petitionGamma/details/shared/waypoints';
import { MembershipAwareness } from 'src/app/shared/components/membershipAwareness';

import { PetitionStarterViewportNormal } from '../components/PetitionStarterViewportNormal';
import { PetitionStarterViewportSmall } from '../components/PetitionStarterViewportSmall';

import { DecisionMakerResponsesContainer } from './decisionMakers/DecisionMakerResponses';
import { DecisionMakersContainer } from './decisionMakers/DecisionMakers';
import { DescriptionContainerViewportNormal } from './DescriptionViewportNormal';
import { DescriptionContainerViewportSmall } from './DescriptionViewportSmall';
import { HeadingContainerMobile } from './HeadingMobile';
import { MediaHitsContainer } from './MediaHitsContainer';
import { MetaAttributes } from './MetaAttributes';
import { MobileActionButtonContainer } from './mobileActionButton';
import { PetitionUrlQRCode } from './QRCode';
import { ReportAbuseContainer } from './ReportAbuse';
import { SapCtaContainer } from './SapCta';
import { ScrollTrackingWaypointContainer } from './ScrollTrackingWaypoint';
import { SideContainer } from './side';
import { SignaturesDistributionContainer } from './SignaturesDistributionContainer';
import { SupporterVoices } from './supportersVoices/SupportersVoices';
import { UpdatesAndCommentsContainer } from './updatesAndComments';

/*
Using CSS grid for better keyboard/swipe navigation
╔═══╦═══╗
║ 1 ║   ║
╠═══╣ 2 ║
║ 3 ║   ║
╚═══╩═══╝
*/

export function MainContainer(): JSX.Element {
	const {
		data: {
			petition: { id: petitionId, slug },
		},
	} = usePetitionDetailsPageData();
	return (
		<Box
			sx={{
				gridTemplateColumns: '66.66667% 33.33333%',
				// eslint-disable-next-line @typescript-eslint/naming-convention
				'&': {
					// necessary to override ms-grid above
					display: ['block', 'grid'],
				},
			}}
		>
			<Box sx={{ gridColumn: '1 / 1' }} pr={[0, 40]}>
				<ResponsiveBox viewportSize="small">
					<DecisionMakerResponsesContainer />
				</ResponsiveBox>

				<HeadingContainerMobile />

				<Box px={[16, 8]}>
					<Box>
						<MetaAttributes />
					</Box>
					<ResponsiveBox viewportSize="normal">
						<PetitionStarterViewportNormal />
						<DescriptionContainerViewportNormal />
					</ResponsiveBox>
					<ResponsiveBox viewportSize="small">
						<DescriptionContainerViewportSmall />
						<PetitionStarterViewportSmall />
					</ResponsiveBox>
					<ScrollTrackingWaypointContainer name="scroll_past_body" />
					<AfterDescriptionWaypoint />
					<Box py={2}>
						<SupporterVoices />
					</Box>
				</Box>
			</Box>
			<Box sx={{ gridColumn: '2 / 2', gridRow: '1 / span 2' }}>
				<ResponsiveBox viewportSize="small">
					<MobileActionButtonContainer sticky />
				</ResponsiveBox>
				<ResponsiveBox viewportSize="normal" px={8} pb={24} sx={{ height: '100%' }}>
					<SideContainer />
				</ResponsiveBox>
			</Box>
			<Box sx={{ gridColumn: '1 / 1', gridRow: '2 / 2' }} pr={[0, 40]}>
				<Box px={[16, 8]}>
					<PetitionUrlQRCode />
					<ReportAbuseContainer />
					<SapCtaContainer />
				</Box>
				<Separator role="presentation" my={24} />
				<SignaturesDistributionContainer />
				<DecisionMakersContainer />
				<MembershipAwareness sourceLocation="petition_details" petitionId={petitionId} slug={slug} />
				<MediaHitsContainer />
				<ScrollTrackingWaypointContainer name="scroll_updates_section" />
				<UpdatesAndCommentsContainer />
			</Box>
		</Box>
	);
}

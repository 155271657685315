import React from 'react';

import { Button } from '@change/design-system/components/actions';
import { iconDelete } from '@change/design-system/icons';

import type { MediaHitEmbed } from '../../shared/types';

import { useDeleteMediaHit } from './hooks/useDeleteMediaHit';

type DeleteMediaHitProps = {
	media: MediaHitEmbed;
	removeMedia: (mediaId: string) => void;
};

export function GammaDeleteMediaHit({ media, removeMedia }: DeleteMediaHitProps): React.JSX.Element | null {
	const {
		data: { canDeleteMediaHit },
		actions: { handleDeleteClick },
	} = useDeleteMediaHit(media, removeMedia);

	if (!canDeleteMediaHit) return null;

	return (
		<Button
			size="small"
			mode="icon"
			icon={iconDelete}
			variant="secondary"
			aria-label={`delete media from ${media.url}`}
			data-testid={`media-hit-${media.id}-delete-button`}
			sx={{
				border: 'none',
				background: 'primary-greyBackground',
				// eslint-disable-next-line @typescript-eslint/naming-convention
				'&:hover': {
					background: 'rgba(0,0,0,0.1)',
				},
			}}
			onClick={handleDeleteClick}
		>
			Delete
		</Button>
	);
}

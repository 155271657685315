import { type ComponentPropsWithoutRef, type JSX, useEffect, useRef, useState } from 'react';

import { Box } from '@change/design-system/layout';

import { checkReduceMotion } from '../../utils';

import { checkScrollVisibility, findScrollTargets } from './utils';

const DEFAULT_INTERVAL_SECONDS = 3;

type Props = ComponentPropsWithoutRef<typeof Box> & {
	intervalSeconds?: number;
};

/**
 * Displays its children in a horizontally-scrolling list that automatically scrolls every
 * `intervalSeconds`. When the last element is scrolled into view, the auto-scrolling stops, giving
 * fully control to the user.
 *
 * If the browser has `prefers-reduced-motion` set, then no auto-scrolling happens.
 *
 * The scrollbar for this element is hidden.
 */
export function ActivityScroller({
	intervalSeconds = DEFAULT_INTERVAL_SECONDS,
	children,
	sx,
	...rest
}: Props): JSX.Element {
	const [shouldAnimate, setShouldAnimate] = useState(() => !checkReduceMotion());
	const scrollerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const scroller = scrollerRef.current;
		if (!(scroller && shouldAnimate)) {
			return () => {};
		}

		const handleInterval = () => {
			const { next, last } = findScrollTargets(scroller);

			// Stop animating once the end is reached.
			if (last && checkScrollVisibility(scroller, last).right) {
				setShouldAnimate(false);
				return;
			}

			if (next) {
				// Note that this doesn't guarantee perfect alignment. The scroll-snap handles that.
				scroller.scroll({
					left: next.offsetLeft,
				});
			}
		};

		const intervalId = setInterval(handleInterval, intervalSeconds * 1_000);

		return () => {
			clearInterval(intervalId);
		};
	}, [intervalSeconds, shouldAnimate]);

	return (
		<Box
			ref={scrollerRef}
			sx={{
				whiteSpace: 'nowrap',
				overflowX: 'scroll',
				scrollBehavior: 'smooth',
				scrollbarWidth: 'none',
				scrollSnapType: 'inline proximity',
				// Making each child scroll-snap simplifies the math we have to do in the scroll handler.
				// (Account for margin, etc.)
				// eslint-disable-next-line @typescript-eslint/naming-convention
				'> *': {
					scrollSnapAlign: 'start',
				},
				// Fallback for hiding scrollbar in older browsers:
				// eslint-disable-next-line @typescript-eslint/naming-convention
				'::-webkit-scrollbar': {
					display: 'none',
				},
				...sx,
			}}
			{...rest}
		>
			{children}
		</Box>
	);
}

import type { JSX } from 'react';

import { Box } from '@change/design-system/layout';
import { Heading } from '@change/design-system/typography';

import { usePetitionDetails } from '../hooks/petitionDetails/petitionDetails';

export function HeadingContainer(): JSX.Element {
	const details = usePetitionDetails();
	return (
		<Box pt={[20, 40]} mb={[10, 60]}>
			<Box>
				<Heading
					as="h1"
					size="h2"
					pt={0}
					breakWord
					data-qa="petition-title"
					sx={{
						textAlign: ['left', 'center'],
						lineHeight: '44px',
					}}
				>
					{details.ask}
				</Heading>
			</Box>
		</Box>
	);
}

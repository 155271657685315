import { useCallback, useState } from 'react';

import type { FormApi } from 'final-form';

import { useUtilityContext } from '@change/core/react/utilityContext';

import { useCampaignTracking } from 'src/app/shared/hooks/campaignTracking';

import { usePetitionDetails } from '../../../../../../hooks/petitionDetails';
import { useUserInfo } from '../../../../../../hooks/userInfo';
import { useMediaHitsContext } from '../../../../shared/provider';
import type { MediaHitValues } from '../../../../shared/types';
import { createMediaHit } from '../../api/createMediaHit';
import { useValidateLink } from '../useValidateLink';

type Result = ModelHookResult<
	{ loading: boolean },
	{
		validateLink: (value: string) => string | undefined;
		handleSubmitMediaHit: (
			values: MediaHitValues,
			form: FormApi<MediaHitValues, Partial<MediaHitValues>>,
		) => Promise<void>;
	}
>;

// eslint-disable-next-line max-lines-per-function
export function useAddMediaHit(): Result {
	const [loading, setLoading] = useState(false);

	const {
		data: { isMaxMediaLimitReached },
		actions: { addMedia, setSubmitStatus },
	} = useMediaHitsContext();

	const { track, trackError } = useCampaignTracking();
	const utilityContext = useUtilityContext();

	const validateLink = useValidateLink();

	const { id } = usePetitionDetails();

	const { ownPetition } = useUserInfo();

	const handleSubmitMediaHit = useCallback(
		// eslint-disable-next-line max-statements
		async (formValues: { link: string }, form: FormApi<MediaHitValues, Partial<MediaHitValues>>) => {
			const trackingData = {
				petition_id: id,
				media_url: formValues.link,
			};
			if (ownPetition && isMaxMediaLimitReached) {
				setSubmitStatus('max_limit');
				track('media_hit_maximum_reached', { petition_id: id });
				return;
			}
			try {
				setLoading(true);
				setSubmitStatus(undefined);
				const result = await createMediaHit(formValues.link, id, utilityContext);
				if (result?.success) {
					setSubmitStatus('success');
					form.reset();
					if (result.comment) {
						addMedia(result?.comment.id, result?.comment.comment);
					}
					track('campaign_media_hit_create_success', trackingData);
				} else {
					setSubmitStatus('error');
					track('campaign_media_hit_create_failure', trackingData);
				}
			} catch (err) {
				setSubmitStatus('error');
				trackError('campaign_media_hit_create_error', err, {
					params: trackingData,
				});
			} finally {
				setLoading(false);
			}
		},
		[id, isMaxMediaLimitReached, ownPetition, addMedia, setSubmitStatus, track, trackError, utilityContext],
	);

	return {
		data: { loading },
		actions: {
			handleSubmitMediaHit,
			validateLink,
		},
	};
}

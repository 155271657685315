import type { JSX } from 'react';

import { Translate } from '@change/core/react/i18n';

import { ActionButtonWrapper } from './ActionButtonWrapper';
import { CustomButton } from './CustomButton';
import { useMobileRespondModal } from './MobileRespondModal';

type Props = {
	sticky: boolean;
	visible: boolean;
};

export function MobileRespondButtonContainer({ sticky, visible }: Props): JSX.Element {
	const openModal = useMobileRespondModal();

	return (
		<ActionButtonWrapper visible={visible} sticky={sticky}>
			<CustomButton onClick={openModal} data-qa="mobile-share-button">
				<Translate value="fe.components.petition_decision_maker_respond.action" />
			</CustomButton>
		</ActionButtonWrapper>
	);
}

import type { JSX } from 'react';

import { Helmet } from 'react-helmet-async';

import type { SubEnvironment } from '@change/config/environments';
import { facebookNamespaces } from '@change/config/facebook';
import { useUtilityContext } from '@change/core/react/utilityContext';

export function MetaTypeContainer(): JSX.Element | null {
	const { environment } = useUtilityContext();

	const env = environment.getDemoEnvironment() || environment.getEnvironment();
	const namespace = facebookNamespaces[env as SubEnvironment] || undefined;

	return <Helmet>{namespace && <meta property="og:type" content={`${namespace}:petition`} />}</Helmet>;
}

import React from 'react';

import { Translate } from '@change/core/react/i18n';
import { Avatar } from '@change/design-system/components/media';
import { Flex } from '@change/design-system/layout';
import { Text } from '@change/design-system/typography';

import { StarterLink } from '../../containers/MetaAttributes/components/StarterLink';
import { usePetitionDetails } from '../../hooks/petitionDetails';
import { usePetitionStarterPhoto } from '../../hooks/petitionStarterPhoto';

const AVATAR_SIZE = 44;

export function PetitionStarterViewportNormal(): React.JSX.Element {
	const { organization, user } = usePetitionDetails();
	const { photoUrl } = usePetitionStarterPhoto();

	return (
		<>
			<h2>
				<Translate value="corgi.petition.details.body_title" />
			</h2>

			<Flex sx={{ gap: 8 }} mb={16} mt={24}>
				<Avatar variant="subtle" alt="avatar of the starter" src={photoUrl} size={AVATAR_SIZE} />
				<Flex
					sx={{
						flex: 1,
						// min-height + center so that 1 line is centered on the avatar
						// and 3+ lines are aligned with the avatar at the top
						minHeight: AVATAR_SIZE,
						alignItems: 'center',
					}}
				>
					<Text
						sx={{
							// half the avatar height, so that 2 lines match the avatar's height
							lineHeight: `${AVATAR_SIZE / 2}px`,
						}}
						color="typography-secondary"
					>
						<Translate value="corgi.petition.details.started_by" />
						&nbsp;
						<StarterLink user={user} organization={organization} />
					</Text>
				</Flex>
			</Flex>
		</>
	);
}

import type { ComponentProps, JSX } from 'react';

import { Translate, TranslatePlural, useI18n } from '@change/core/react/i18n';
import { InlineMessage } from '@change/design-system/components/alerts';
import { Loader } from '@change/design-system/components/progressiveDisclosure';
import { Box } from '@change/design-system/layout';
import { createModalHook } from '@change/design-system/modals';

import {
	PetitionDetailsPageDataContextProvider,
	usePetitionDetailsPageData,
} from 'src/app/pages/petitionGamma/details/pageContext';
import { UpdateCard } from 'src/app/pages/petitionGamma/details/shared/components';
import type { PetitionContentUpdate } from 'src/app/pages/petitionGamma/details/shared/types';
import { isError, isLoading } from 'src/app/shared/utils/async';

import { useApiDecisionMakerResponses } from '../../hooks/decisionMakers/apiDecisionMakerResponses';

function Update({ update }: { update: PetitionContentUpdate }): JSX.Element | null {
	return (
		<Box my={[8, 16]}>
			<UpdateCard update={update} />
		</Box>
	);
}

export const useDecisionMakerResponsesModal = createModalHook<{
	pageData: ComponentProps<typeof PetitionDetailsPageDataContextProvider>['pageData'];
}>({
	name: 'DecisionMakerResponsesModal',
	title: function DecisionMakerResponsesModalTitle() {
		const {
			data: {
				petition: {
					dmResponsesConnection: { totalCount },
				},
			},
		} = usePetitionDetailsPageData();
		const { localizeNumber } = useI18n();
		return (
			<TranslatePlural
				value="fe.pages.decision_maker_show.response_count"
				count={totalCount}
				replacements={{ responseCount: localizeNumber(totalCount) }}
			/>
		);
	},
	body: function DecisionMakerResponsesModalBody() {
		const {
			data: {
				petition: {
					id,
					dmResponsesConnection: { totalCount },
				},
			},
		} = usePetitionDetailsPageData();
		const updates = useApiDecisionMakerResponses(id, totalCount);

		if (isLoading(updates)) return <Loader size="m" mx="auto" my={24} />;

		if (isError(updates))
			return (
				<InlineMessage size="large" variant="error">
					<Translate value="fe.errors.generic_try_again" />
				</InlineMessage>
			);

		return (
			// using role due to https://www.scottohara.me/blog/2019/01/12/lists-and-safari.html
			<Box as="ul" role="list">
				{updates.updates?.updates.map((update) => (
					<Box as="li" key={update.id}>
						<Update update={update} />
					</Box>
				))}
			</Box>
		);
	},
	decorator: ({ children, options: { pageData } }) => {
		return (
			<PetitionDetailsPageDataContextProvider pageData={pageData}>{children}</PetitionDetailsPageDataContextProvider>
		);
	},
});

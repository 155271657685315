import { useCallback } from 'react';

import { useTracking } from '@change/core/react/tracking';

import { usePetitionDetails } from './petitionDetails';
import { usePetitionSignatureCount } from './petitionStats';
import { useCampaignActionsEnabled } from './useCampaignActionsEnabled';

export function useNextStepButtonAction() {
	const { slug, id } = usePetitionDetails();
	const {
		signatureCount: { displayed },
	} = usePetitionSignatureCount();
	const track = useTracking();
	const campaignActionsEnabled = useCampaignActionsEnabled();

	const baseLink = `/p/${slug}/psf/promote_or_share`;
	const comboLink = campaignActionsEnabled
		? baseLink.concat('?allow_actions=true&utm_campaign=take_next_step')
		: baseLink;

	const handleNextStepClick = useCallback(() => {
		void track('petition_next_step_click', {
			petition_id: `petition_${id}`,
			signatureCount: displayed,
			campaignActionsEnabled,
		});
	}, [track, id, campaignActionsEnabled, displayed]);

	return {
		data: { comboLink },
		action: { handleNextStepClick },
	};
}

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';

import { Box, Flex } from '@change/design-system/layout';
import { Heading } from '@change/design-system/typography';

import { UserIsInVariantOrStaffOrInSet } from 'src/app/shared/components/access';
import { usePetitionFeedAccessControlFc } from 'src/app/shared/hooks/petitionFeed';
import { isLoaded } from 'src/app/shared/utils/async';

import { usePetitionDetails } from '../../hooks/petitionDetails';
import { useSupporterVideos } from '../../hooks/supporterVoices/supporterVideos';

import { CreateVideo } from './components/CreateVideo';
import { VideoComponent } from './components/VideoComponent';
import { ViewPetitionFeed } from './components/ViewPetitionFeed';

export function SupporterVoicesComponent({
	showSupporterVoices,
}: {
	showSupporterVoices: boolean;
}): React.JSX.Element | null {
	const {
		data: { currentlyPlaying, supporterVideos, isFetchError, showCreateVideo },
		actions: { setCurrentlyPlaying, removeVideoFromState },
	} = useSupporterVideos({ showSupporterVoices });
	const { id: petitionId, slug } = usePetitionDetails();

	const petitionFeedFc = usePetitionFeedAccessControlFc();

	if (isFetchError) return null;

	if (supporterVideos.length === 0) return null;

	if (!isLoaded(petitionFeedFc) || !petitionFeedFc.values.config) return null;

	if (!showSupporterVoices) {
		return <Box sx={{ display: 'none' }} data-testid="petition-page-supporters-voices-hidden"></Box>;
	}

	return (
		<Box data-testid="petition-page-supporters-voices">
			<Heading as="h2" sx={{ fontSize: '24' }} my={18}>
				Top supporter voices
			</Heading>
			<Box
				sx={{
					// This creates a scrolling carousel.
					maxWidth: '100%',
					overflowX: 'auto',
					overflowY: 'hidden',
					position: 'relative',
				}}
			>
				<Flex
					sx={{
						gap: 16,
					}}
				>
					{supporterVideos?.map((video) => {
						return (
							<VideoComponent
								video={video}
								currentlyPlaying={currentlyPlaying}
								key={video.id}
								setCurrentlyPlaying={setCurrentlyPlaying}
								removeVideoFromState={removeVideoFromState}
							/>
						);
					})}
					{showCreateVideo && <CreateVideo />}
				</Flex>
			</Box>
			<UserIsInVariantOrStaffOrInSet
				featureAccessConfigValue={petitionFeedFc.values.config}
				variant="feed"
				fallback={null}
			>
				<ViewPetitionFeed petitionId={petitionId} slug={slug} />
			</UserIsInVariantOrStaffOrInSet>
		</Box>
	);
}

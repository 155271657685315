import { type ComponentProps } from 'react';

import { Translate } from '@change/core/react/i18n';
import { InlineMessage } from '@change/design-system/components/alerts';
import { Loader } from '@change/design-system/components/progressiveDisclosure';
import { createModalHook } from '@change/design-system/modals';

import {
	PetitionDetailsPageDataContextProvider,
	PetitionDetailsPageFcmContextProvider,
} from 'src/app/pages/petitionGamma/details/pageContext';
import { isError, isLoading } from 'src/app/shared/utils/async';

import { useApiDecisionMakers } from '../../hooks/decisionMakers/apiDecisionMakers';
import type { PetitionDetailsFcm } from '../../hooks/fcm';

import { DecisionMakersModalInner } from './DecisionMakersModalInner';

export const useDecisionMakersModal = createModalHook<{
	pageData: ComponentProps<typeof PetitionDetailsPageDataContextProvider>['pageData'];
	fcm: PetitionDetailsFcm;
}>({
	name: 'DecisionMakersModal',
	title: () => <Translate value="fe.pages.petition_new.decision_maker" />,
	body: function DecisionMakersModalBody({
		options: {
			pageData: {
				data: {
					petition: {
						slug,
						primaryDecisionMakerConnection: { totalCount },
						dmResponsesConnection: { nodes: allDmResponses },
					},
				},
			},
		},
	}) {
		const apiDmResult = useApiDecisionMakers(slug, totalCount);

		if (isLoading(apiDmResult)) return <Loader size={['s', 'm']} mx="auto" my={16} />;

		if (isError(apiDmResult))
			return (
				<InlineMessage size="large" variant="error">
					<Translate value="fe.errors.generic_try_again" />
				</InlineMessage>
			);

		return (
			<DecisionMakersModalInner
				allDmResponses={allDmResponses}
				decisionMakers={[...(apiDmResult.decisionMakers || [])]}
			/>
		);
	},
	decorator: ({ children, options: { pageData, fcm } }) => {
		return (
			<PetitionDetailsPageDataContextProvider pageData={pageData}>
				<PetitionDetailsPageFcmContextProvider pageFcm={fcm}>{children}</PetitionDetailsPageFcmContextProvider>
			</PetitionDetailsPageDataContextProvider>
		);
	},
});

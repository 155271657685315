import type { JSX } from 'react';

import { Translate } from '@change/core/react/i18n';
import { Flex } from '@change/design-system/layout';
import { Text } from '@change/design-system/typography';

import { BadgeScore } from 'src/app/shared/components/badgeScore';

type Props = { score: number };
export function PetitionStrengthCard({ score }: Props): JSX.Element {
	return (
		<Flex
			p={24}
			sx={{
				backgroundColor: 'background',
				borderRadius: 'standard',
				flexDirection: 'column',
				gap: 16,
				height: '100%',
				minWidth: 200,
			}}
		>
			<Text as="p" size="small" fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>
				<Translate value="corgi.petition.details.strength_score.title" />
			</Text>
			<BadgeScore
				score={score}
				sx={{
					// eslint-disable-next-line @typescript-eslint/naming-convention
					'& #score-text': { fontSize: '14px' },
				}}
			/>
		</Flex>
	);
}

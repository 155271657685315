import gql from 'graphql-tag';

import type { UtilityContext } from '@change/core/react/utilityContext';

import type { GammaPetitionPageVideosQuery, GammaPetitionPageVideosQueryVariables } from './index.graphql';

export const fetchSupporterVideos = async ({
	petitionId,
	utilityContext: {
		gql: { fetch },
	},
}: {
	petitionId: string;
	utilityContext: UtilityContext;
}): Promise<GammaPetitionPageVideosQuery | null | undefined> => {
	const { data } = await fetch<NonNullable<GammaPetitionPageVideosQuery>, GammaPetitionPageVideosQueryVariables>({
		query: gql`
			query GammaPetitionPageVideos($petitionId: ID!, $pageOffset: Int!, $pageSize: Int!, $orderBy: String) {
				getPetitionVideosWithLikes(
					petitionId: $petitionId
					pageOffset: $pageOffset
					pageSize: $pageSize
					orderBy: $orderBy
				) {
					isLastPage
					petitionVideosWithLikes {
						id
						videoUrl
						likeCount
						createdAt
						hasCurrentUserLiked
						user {
							id
							shortDisplayName
						}
					}
				}
			}
		`,
		variables: { petitionId, pageOffset: 0, pageSize: 10, orderBy: 'voting_score' },
		important: true,
	});

	return data;
};

import type { JSX } from 'react';

import { Loader } from '@change/design-system/components/progressiveDisclosure';
import { Box, Flex } from '@change/design-system/layout';

export function PhotoProcessing(): JSX.Element {
	return (
		<Box aria-hidden="true" sx={{ overflow: 'hidden' }}>
			<Flex
				sx={{
					aspectRatio: '16 / 9',
					height: '100%',
					alignItems: 'center',
					justifyContent: 'center',
					overflow: 'hidden',
				}}
			>
				<Loader size="xs" />
			</Flex>
		</Box>
	);
}

import { useMemo } from 'react';

import compact from 'lodash/compact';

import { usePetitionDetailsPageData } from 'src/app/pages/petitionGamma/details/pageContext';

export type RecentSigner = {
	id: string;
	displayName: string;
	timestampRelativeLocalized: string;
};

export function useRecentSigners(): RecentSigner[] {
	const {
		data: {
			petition: { recentSignersConnection },
		},
	} = usePetitionDetailsPageData();

	const recentSigners = useMemo(
		() =>
			compact(
				recentSignersConnection.edges.map((edge): RecentSigner | undefined => {
					const {
						timestampRelativeLocalized,
						node: { id, displayName },
					} = edge;
					if (!timestampRelativeLocalized) {
						return undefined;
					}

					return { id, displayName, timestampRelativeLocalized };
				}),
			),

		[recentSignersConnection],
	);

	return recentSigners;
}

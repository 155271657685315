import gql from 'graphql-tag';

import type { UtilityContext } from '@change/core/react/utilityContext';

import type {
	GammaPetitionPagePetitionInfoQuery,
	GammaPetitionPagePetitionInfoQueryVariables,
} from './petition.graphql';

export type PartialPetition = NonNullable<GammaPetitionPagePetitionInfoQuery['petition']>;

export async function getPetitionInfo(
	slugOrId: string,
	{ gql: { fetch } }: UtilityContext,
): Promise<PartialPetition | undefined> {
	const { data } = await fetch<GammaPetitionPagePetitionInfoQuery, GammaPetitionPagePetitionInfoQueryVariables>({
		query: gql`
			query GammaPetitionPagePetitionInfo($slugOrId: String!) {
				petition: petitionBySlugOrId(slugOrId: $slugOrId) {
					id
					ask
					shouldHideUserActions
					emergencyBannerContent
				}
			}
		`,
		variables: { slugOrId },
		rejectOnError: true,
	});
	return data?.petition || undefined;
}

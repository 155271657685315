import { useEffect } from 'react';

import { useTracking } from '@change/core/react/tracking';

import { iframely } from 'src/app/shared/utils/iframely';

import { usePetitionDetails } from '../../../../../../hooks/petitionDetails';
import { useUserInfo } from '../../../../../../hooks/userInfo';
import { useMediaHitsContext } from '../../../../shared/provider';

type Result = ModelHookResult<{
	petitionId: string;
	isStaffOrStarter: boolean;
}>;

export function useMediaHits(): Result {
	const {
		data: { mediaEmbeds },
	} = useMediaHitsContext();

	const { id: petitionId } = usePetitionDetails();
	const track = useTracking();

	const { canEdit } = useUserInfo();

	useEffect(() => {
		if (!mediaEmbeds.length) return undefined;

		return iframely.on('click', (href) => {
			const theClickedEmbed = mediaEmbeds.find((m) => m.url === href);
			void track('petition_media_feed_click', {
				url: href,
				petition_id: petitionId,
				media_source: theClickedEmbed?.mediaSource,
			});
		});
	}, [track, mediaEmbeds, petitionId]);

	return {
		data: { petitionId, isStaffOrStarter: canEdit },
	};
}

import type { JSX } from 'react';

import { Translate } from '@change/core/react/i18n';
import { VisuallyHidden } from '@change/design-system/a11y';
import { Box } from '@change/design-system/layout';

import { ActivityPill, ActivityScroller } from './components/ActivityScroller';
import { useRecentSigners } from './hooks/useRecentSigners';

const MIN_SIGNERS = 10;

export function RecentSigners(): JSX.Element | null {
	const recentSigners = useRecentSigners();

	if (recentSigners.length < MIN_SIGNERS) {
		return null;
	}

	const firstSigner = recentSigners[0];
	const othersCount = recentSigners.length - 1;

	return (
		<Box
			data-testid="recent-signers"
			sx={{
				// This removes the margin from the MainContainer so that the list appears to scroll to/from
				// offscreen. If we refactor how the small-viewport layout is defined, we could just have
				// this component take up the full width.
				marginX: -16,
			}}
		>
			<ActivityScroller aria-hidden>
				{recentSigners.map((signer) => (
					<ActivityPill
						key={signer.id}
						title={signer.displayName}
						time={signer.timestampRelativeLocalized}
						sx={{
							marginLeft: 16,
							scrollMarginLeft: 16,
						}}
					/>
				))}
			</ActivityScroller>

			<VisuallyHidden>
				<Translate
					value="corgi.petition_gamma.recent_signers.accessible_description"
					replacements={{
						firstSignerName: firstSigner.displayName,
						othersCount,
					}}
				/>
			</VisuallyHidden>
		</Box>
	);
}

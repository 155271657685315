import type { JSX } from 'react';

import { TranslatePlural, useI18n } from '@change/core/react/i18n';
import { Button, ButtonLink } from '@change/design-system/components/actions';
import { Flex } from '@change/design-system/layout';
import { Text } from '@change/design-system/typography';

import { usePetitionDetailsPageData } from 'src/app/pages/petitionGamma/details/pageContext';

import { usePetitionDetails } from '../../hooks/petitionDetails/petitionDetails';

import { useDecisionMakerResponsesModal } from './DecisionMakerResponsesModal';

function DecisionMakerResponses({
	totalCount,
	localizedTotalCount,
}: {
	totalCount: number;
	localizedTotalCount: string;
}): JSX.Element | null {
	const { translatePlural } = useI18n();
	const { ask } = usePetitionDetails();

	return (
		<Text
			size="small"
			aria-label={translatePlural('corgi.petition.details.decision_makers_responses.link_a11y', totalCount, {
				num: localizedTotalCount,
				petitionTitle: ask,
			})}
		>
			<TranslatePlural
				value="fe.components.petition_title.num_responses"
				count={totalCount}
				replacements={{ num: localizedTotalCount }}
			/>
		</Text>
	);
}

export function DecisionMakerResponsesContainer(): JSX.Element | null {
	const pageData = usePetitionDetailsPageData();
	const openModal = useDecisionMakerResponsesModal();

	const {
		data: { petition },
	} = pageData;

	if (!petition.dmResponsesConnection.totalCount) {
		return null;
	}

	const {
		dmResponsesConnection: { totalCount, totalCountLocalized },
	} = petition;
	const firstResponse = petition.dmResponsesConnection.nodes[0];

	const url = `/p/${petition.slug}/responses/${firstResponse.id}`;

	const buttonStyle = {
		fontWeight: 'normal',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		'&:focus': {
			backgroundColor: 'neutral-grey100',
		},
	};

	return (
		<Flex
			mt={[16, 32]}
			sx={{
				justifyContent: 'center',
			}}
		>
			{totalCount === 1 ? (
				<ButtonLink variant="secondary" size="small" sx={buttonStyle} to={url}>
					<DecisionMakerResponses totalCount={totalCount} localizedTotalCount={totalCountLocalized} />
				</ButtonLink>
			) : (
				<Button variant="secondary" size="small" sx={buttonStyle} onClick={() => openModal({ pageData })}>
					<DecisionMakerResponses totalCount={totalCount} localizedTotalCount={totalCountLocalized} />
				</Button>
			)}
		</Flex>
	);
}

import { type JSX, useCallback, useState } from 'react';

import { Translate, TranslatePlural } from '@change/core/react/i18n';
import { Icon } from '@change/design-system/components/icon';
import { iconChevronRight } from '@change/design-system/icons';
import { Box, Flex } from '@change/design-system/layout';
import { buttonResetStyles } from '@change/design-system/reset';
import { Heading, Text } from '@change/design-system/typography';

import { useCampaignTracking } from 'src/app/shared/hooks/campaignTracking';

import { usePetitionDetails } from '../../../hooks/petitionDetails';

import { VerifiedSignersIcon } from './VerifiedSignersIcon';

type Props = {
	count: number;
	localizedCount: string;
};

export function SignatureCountMobile({ count, localizedCount }: Props): JSX.Element {
	const [clicked, setClicked] = useState<boolean>();
	const { track } = useCampaignTracking();
	const { id } = usePetitionDetails();

	const openVerifiedSignaturesText = useCallback(() => {
		setClicked(!clicked);
		const trackingData = {
			petition_id: id,
			opened: !clicked,
		};
		track('campaign_action_show_verified_signers_text_click', trackingData);
	}, [id, clicked, track]);

	return (
		<Box>
			<Flex
				mb={8}
				sx={{
					justifyContent: 'center',
					flexDirection: 'column',
					alignItems: 'center',
					gap: 8,
				}}
			>
				<Box>
					<Flex sx={{ flexDirection: 'row', gap: 4 }}>
						<Heading as="h2" sx={{ fontSize: '48px' }}>
							{localizedCount}
						</Heading>
						<Box>
							<VerifiedSignersIcon width="1em" height="1em" />
						</Box>
					</Flex>
				</Box>
				<button
					type="button"
					onClick={openVerifiedSignaturesText}
					sx={{
						...buttonResetStyles,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						gap: 4,
						// eslint-disable-next-line @typescript-eslint/naming-convention
						'&:hover': {
							cursor: 'pointer',
						},
					}}
				>
					<Text size="default">
						<TranslatePlural value="corgi.petition_gamma.details.verified_signers_text.title" count={count} />
					</Text>
					<Icon
						size={24}
						icon={iconChevronRight}
						data-testid="verified-signatures-toggle-text"
						sx={{
							border: 'none',
							transform: clicked ? 'rotate(-90deg)' : 'rotate(90deg)',
						}}
					></Icon>
				</button>
				{clicked && (
					<Text
						as="div"
						size="default"
						sx={{ textAlign: 'center', fontStyle: 'italic', color: 'neutral-grey600' }}
						data-testid="verified-signatures-text"
					>
						<Translate value="corgi.petition_gamma.details.verified_signers_text.body" />
					</Text>
				)}
			</Flex>
		</Box>
	);
}

import { translationKey } from '@change/core/i18n';

import type { ScorablePetition, TipCardConfig } from './types';

type PartialTipCardConfig = Omit<TipCardConfig, 'url' | 'onClick'>;

export const TIPS_CONFIGS: PartialTipCardConfig[] = [
	{
		type: 'description',
		title: translationKey('corgi.petition.details.strength_score.description.title'),
		body: translationKey('corgi.petition.details.strength_score.description.body'),
		primaryActionText: translationKey('corgi.petition.details.strength_score.description.text_link'),
		verifyFunction: (petition: ScorablePetition) => petition.description.length <= 2000,
	},
	{
		type: 'media',
		title: translationKey('corgi.petition.details.strength_score.image.title'),
		body: translationKey('corgi.petition.details.strength_score.image.body'),
		primaryActionText: translationKey('corgi.petition.details.strength_score.image.text_link'),
		verifyFunction: (petition: ScorablePetition) => !petition.photo?.petitionLarge,
	},
	{
		type: 'tags',
		title: translationKey('corgi.petition.details.strength_score.category.title'),
		body: translationKey('corgi.petition.details.strength_score.category.body'),
		primaryActionText: translationKey('corgi.petition.details.strength_score.category.text_link'),
		verifyFunction: (petition: ScorablePetition) => (petition.tagsConnection?.nodes?.length || 0) <= 1,
	},
	{
		type: 'decision_maker',
		title: translationKey('corgi.petition.details.strength_score.decision_maker.title'),
		body: translationKey('corgi.petition.details.strength_score.decision_maker.body'),
		primaryActionText: translationKey('corgi.petition.details.strength_score.decision_maker.text_link'),
		verifyFunction: (petition: ScorablePetition) => (petition.primaryDecisionMakerConnection?.totalCount || 0) === 0,
	},
	{
		type: 'smart_images',
		title: translationKey('corgi.petition.details.strength_score.image_test.title'),
		body: translationKey('corgi.petition.details.strength_score.image_test.body'),
		primaryActionText: translationKey('corgi.petition.details.strength_score.image_test.text_link'),
		verifyFunction: (petition: ScorablePetition) => !petition.hasHadShareImageBandit,
	},
];

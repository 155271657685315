import { useEffect } from 'react';

import { Translate } from '@change/core/react/i18n';
import { createModalHook } from '@change/design-system/modals';

import { preloadShare, ShareContainer } from '../share';

import { MobileModalContextDecorator } from './shared/MobileModalContextDecorator';
import type { MobileModalContext } from './shared/types';

const PRELOAD_SHARE_DELAY = 5000;

export const useMobileShareModal = createModalHook<MobileModalContext>({
	name: 'MobileShareModal',
	variant: 'fullPage',
	closeOnBreakpoints: [false, true],
	title: function MobileShareModalTitle() {
		return <Translate value="fe.components.petition_share.action" />;
	},
	body: function MobileShareModalBody() {
		return <ShareContainer forMobileView />;
	},
	decorator: ({ children, options }) => (
		<MobileModalContextDecorator {...options}>{children}</MobileModalContextDecorator>
	),
});

export function usePreloadMobileShareModal(): void {
	useEffect(() => {
		const timeout = window.setTimeout(() => {
			void preloadShare();
		}, PRELOAD_SHARE_DELAY);

		return () => window.clearTimeout(timeout);
	});
}

import type { JSX, PropsWithChildren } from 'react';

import { Icon } from '@change/design-system/components/icon';
import type { IconComponent } from '@change/design-system/icons';
import { Box } from '@change/design-system/layout';
import { Heading } from '@change/design-system/typography';

type Props = {
	icon: IconComponent;
	iconColor: string;
};

export function SidePetitionStatus({ icon, iconColor, children }: PropsWithChildren<Props>): JSX.Element {
	return (
		<Heading as="h3" size={['h4', 'h3']} mb={8}>
			<Box sx={{ position: 'relative', display: 'inline-block', bottom: -2 }} mr={8}>
				<Icon icon={icon} size="xl" color={iconColor} />
			</Box>
			{children}
		</Heading>
	);
}

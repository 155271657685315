import { useCallback } from 'react';

import { useI18n } from '@change/core/react/i18n';

import { useQRCodeShare } from './useQRCodeShare';

type UsePetitionUrlQRCodeData = {
	value: string;
	descriptionText: string;
	downloadLinkText: string;
	downloadFilename: string;
};
type UsePetitionUrlQRCodeActions = { handleDownload: () => Promise<void> };
type UsePetitionUrlQRCodeReturn = ModelHookResult<UsePetitionUrlQRCodeData, UsePetitionUrlQRCodeActions>;

export function usePetitionUrlQRCode(): UsePetitionUrlQRCodeReturn {
	const { translate } = useI18n();
	const descriptionText = translate('corgi.petition.details.qr_code.description_text');
	const downloadLinkText = translate('corgi.petition.details.qr_code.download_link_text');
	const downloadFilename = translate('corgi.petition.details.qr_code.filename');

	const shareAction = useQRCodeShare();
	const qrcodeValue = shareAction?.shortUrl ?? shareAction?.url ?? '';
	const handleDownload = useCallback(async () => {
		await shareAction?.action();
	}, [shareAction]);

	return {
		data: { value: qrcodeValue, descriptionText, downloadLinkText, downloadFilename },
		actions: { handleDownload },
	};
}

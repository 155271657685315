import { type JSX, type PropsWithChildren } from 'react';

import { Separator } from '@change/design-system/components/content';
import { Box, Flex } from '@change/design-system/layout';

import type { DecisionMaker } from '../../../hooks/decisionMakers';
import { useDmStatus } from '../../../hooks/decisionMakers/useDmStatus';
import type { AllDmResponses } from '../hooks/useDecisionMakers';

import { DmStatus } from './DmStatus';
import { EmailDm } from './EmailDM';

type Props = {
	photoContent: JSX.Element;
	decisionMaker: DecisionMaker;
	allDmResponses: AllDmResponses;
};

export function DecisionMakerListItem({
	photoContent,
	decisionMaker,
	allDmResponses,
	children,
}: PropsWithChildren<Props>): JSX.Element {
	const {
		data: { showDmStatus },
	} = useDmStatus(decisionMaker);

	return (
		<>
			<Separator role="presentation" mt={0} mb={8} sx={{ color: 'neutral-grey100' }} />

			<Flex sx={{ alignItems: 'center' }} mb={20}>
				{photoContent}

				<Box sx={{ flexGrow: 1, overflow: 'hidden' }}>{children}</Box>
			</Flex>

			{showDmStatus && <DmStatus decisionMaker={decisionMaker} allDmResponses={allDmResponses} />}
			{decisionMaker.contactInfo?.email && (
				<EmailDm dmEmail={decisionMaker.contactInfo.email} dmName={decisionMaker.displayName} />
			)}
		</>
	);
}

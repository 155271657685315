import type { ComponentProps, JSX } from 'react';

import { Button } from '@change/design-system/components/actions';

import type { MobileModalContext } from './shared/types';
import { useMobileModalContext } from './shared/useMobileModalContext';

type Props = Omit<ComponentProps<typeof Button>, 'onClick'> & {
	inverted?: boolean;
	onClick?: (context: MobileModalContext) => void;
};

export function CustomButton({ children, inverted, onClick, ...rest }: Props): JSX.Element {
	const context = useMobileModalContext();

	return (
		<Button
			variant={inverted ? 'secondaryEmphasis' : 'primary'}
			sx={{ width: '100%' }}
			onClick={() => onClick?.(context)}
			{...rest}
		>
			{children}
		</Button>
	);
}

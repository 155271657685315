import type { JSX } from 'react';

import { TranslatePlural } from '@change/core/react/i18n';
import { Text } from '@change/design-system/typography';

import { usePetitionSignatureCount } from '../../../hooks/petitionStats';

export function SignatureStatsClosedContainer(): JSX.Element {
	const { signatureCount } = usePetitionSignatureCount();

	return (
		<Text color="typography-secondary">
			<TranslatePlural
				value="fe.pages.petition_show.num_supporters_closed"
				replacements={{
					num_sigs: signatureCount.displayedLocalized,
				}}
				count={signatureCount.displayed}
			/>
		</Text>
	);
}

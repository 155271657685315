import type { JSX } from 'react';

import { Translate } from '@change/core/react/i18n';

import type { SimplifiedDecisionMaker } from 'src/app/pages/petitionGamma/details/shared/types';

type Props = {
	decisionMaker: SimplifiedDecisionMaker;
};

export function DecisionMakerLabel({ decisionMaker }: Props): JSX.Element {
	if (decisionMaker.title) {
		return (
			<Translate
				value="fe.pages.petition_show.petition_show_byline_content.primary_dm_link_with_title"
				replacements={{ decision_maker_title: decisionMaker.title, decision_maker_name: decisionMaker.displayName }}
			/>
		);
	}

	return <span>{decisionMaker.displayName}</span>;
}

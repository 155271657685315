import type { JSX } from 'react';

import { animatePulseStyles } from '@change/design-system/animations';
import { Box } from '@change/design-system/layout';

export function LoadingSkeleton(): JSX.Element {
	return (
		<Box
			sx={{
				background: 'primary-greyBackground',
				borderRadius: 'standard',
				height: '150px',
				width: '100%',
				...animatePulseStyles(500),
			}}
			my={16}
		></Box>
	);
}

import type { JSX } from 'react';

import { Box } from '@change/design-system/layout';

import { usePetitionDetails } from '../../../hooks/petitionDetails';
import { usePetitionSignatureCount } from '../../../hooks/petitionStats';
import { PetitionStatusHeaderContainer } from '../../PetitionStatusHeader';

import { SignatureCountMobile } from './SignatureCountMobile';
import { SignatureStatsActiveContainer } from './SignatureStatsActive';
import { SignatureStatsClosedContainer } from './SignatureStatsClosed';
import { SignatureStatsVictoryContainer } from './SignatureStatsVictory';

function SignatureStatsContainerInner({ isMobile = false }) {
	const { status } = usePetitionDetails();
	const { signatureCount } = usePetitionSignatureCount();

	if (status === 'CLOSED') {
		return <SignatureStatsClosedContainer />;
	}

	if (status === 'VICTORY') {
		return <SignatureStatsVictoryContainer />;
	}

	if (isMobile) {
		return <SignatureCountMobile count={signatureCount.displayed} localizedCount={signatureCount.displayedLocalized} />;
	}

	return <SignatureStatsActiveContainer />;
}

export function SignatureStatsContainer(): JSX.Element {
	return (
		<Box mb={16}>
			<PetitionStatusHeaderContainer />
			<SignatureStatsContainerInner />
		</Box>
	);
}

export function SignatureStatsMobileContainer(): JSX.Element {
	return (
		<Box my={16}>
			<SignatureStatsContainerInner isMobile />
		</Box>
	);
}

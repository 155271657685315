import type { JSX } from 'react';

import { Translate } from '@change/core/react/i18n';
import { VisuallyHidden } from '@change/design-system/a11y';
import { Box } from '@change/design-system/layout';

import { usePetitionDetailsPageData } from 'src/app/pages/petitionGamma/details/pageContext';
import { MembershipAwarenessCard } from 'src/app/shared/components/membershipAwarenessCard';

import { ShareContainer } from '../share';
import { SignatureStatsActiveContainer } from '../stats/signatureStats';

export function SideSignedNonPromotableContainer(): JSX.Element {
	const {
		data: {
			petition: { id: petitionId },
		},
	} = usePetitionDetailsPageData();
	return (
		<>
			<Box mb={8}>
				<SignatureStatsActiveContainer />
			</Box>
			<MembershipAwarenessCard petitionId={petitionId} />
			<VisuallyHidden as="h2">
				<Translate value="fe.components.petition_share.action" />
			</VisuallyHidden>
			<ShareContainer forMobileView={false} />
		</>
	);
}

import { useEffect, useState } from 'react';

import { useTracking } from '@change/core/react/tracking';
import { useUtilityContext } from '@change/core/react/utilityContext';

import { useCanPlayWebm } from 'src/app/shared/hooks/causes';

import { fetchSupporterVideos } from '../../api/fetchSupporterVideos';
import { useFcm } from '../fcm';
import { usePetitionDetails } from '../petitionDetails';
import { useCampaignActionsEnabled } from '../useCampaignActionsEnabled';

import type { SupporterVideosList } from './types';

type Result = ModelHookResult<
	{
		supporterVideos: SupporterVideosList | [];
		isFetchError: boolean;
		currentlyPlaying: string;
		showCreateVideo: boolean;
	},
	{
		setCurrentlyPlaying: (videoUrl: string) => void;
		removeVideoFromState: (videoId?: string) => void;
	}
>;

// eslint-disable-next-line max-lines-per-function
export function useSupporterVideos({ showSupporterVoices }: { showSupporterVoices: boolean }): Result {
	const [supporterVideos, setSupporterVideos] = useState<SupporterVideosList>();
	const [currentlyPlaying, setCurrentlyPlaying] = useState('');
	const [isFetchError, setIsFetchError] = useState(false);

	const utilityContext = useUtilityContext();

	const {
		id: petitionId,
		videoSettingsMetadata: { disableCreation: disableVideoCreation },
	} = usePetitionDetails();
	const canPlayWebm = useCanPlayWebm();
	const track = useTracking();

	const campaignActionsEnabled = useCampaignActionsEnabled();
	const fcm = useFcm();
	const openVideoCreationFromPetitionPage =
		!!fcm.supporterVoicesDetailsPageConfig?.openVideoCreationFromPetitionDetails;

	useEffect(() => {
		async function fetchData() {
			const data = await fetchSupporterVideos({ petitionId, utilityContext });
			if (!data) {
				setIsFetchError(true);
			}
			const petitionVideos = data?.getPetitionVideosWithLikes?.petitionVideosWithLikes;
			const transcodedVideos = petitionVideos?.filter(({ videoUrl }) => videoUrl.endsWith('.mp4'));

			// Right now GQL returns all transcoded + unprocessed videos. We want to display transcoded videos for browsers (e.g., iOS) that don't support .webm.
			const displayVideos = canPlayWebm ? petitionVideos : transcodedVideos;
			setSupporterVideos(displayVideos);
			if (displayVideos && displayVideos.length > 0) {
				void track('petition_page_supporters_voice_view', { petition_id: petitionId, showSupporterVoices });
			}
		}
		void fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [petitionId]);

	const removeVideoFromState = (videoId?: string) => {
		const remainingVideos = supporterVideos?.filter((v) => v.id !== videoId);
		setSupporterVideos(remainingVideos);
	};

	return {
		data: {
			supporterVideos: supporterVideos || [],
			isFetchError,
			currentlyPlaying,
			showCreateVideo: openVideoCreationFromPetitionPage && campaignActionsEnabled && !disableVideoCreation,
		},
		actions: {
			setCurrentlyPlaying,
			removeVideoFromState,
		},
	};
}
